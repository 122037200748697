import { computed, inject, ref } from 'vue'
import { get } from 'lodash'

export default function useFormFields(props) {
  const form = inject('form', ref({}))

  // Form fields are marked for destruction if
  // "destroy" field is set to true
  const markedForDestruction = computed(() => (
    get(form.value?.values, nameToUse('_destroy'))
  ))

  // Prepend a prefix to a field's name
  function nameToUse(name) {
    if (props.namePrefix) {
      return `${props.namePrefix}.${name}`
    }
    return name
  }

  return {
    form,
    nameToUse,
    markedForDestruction,
  }
}
