import { computed, ref } from 'vue'

import {
  storeExcursion,
  updateExcursion,
} from '@shared/http/api'
import useResourceForm from '@shared/hooks/resources/form/resource'
import useResource from '@shared/hooks/resources/resource'

export default function useExcursionForm(options = {}) {
  const defaultResource = {
    attributes: {
      kind: 'customized',
    },
  }

  const resource = options.resource ?? ref(defaultResource)

  const {
    EXCURSION_TYPE,
  } = useResource()

  const apiMethod = computed(() => {
    if (resource?.value?.id) {
      return updateExcursion
    }
    return storeExcursion
  })

  const {
    submitting,
    errors,
    handleSubmit,
  } = useResourceForm({
    apiMethod,
    resource,
    resourceType: EXCURSION_TYPE,
    ...options,
  })

  return {
    resource,
    errors,
    submitting,
    handleSubmit,
  }
}
