<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting">
      <form-group
        rules="required"
        class="with-border-on-control"
        name="excursion_id"
        type="select"
        :form-control-props="{
          filterable: false,
          options: handleExcursionsSearch,
        }"
      />

      <form-group
        name="point_of_interest_id"
        type="hidden"
      />

      <div class="flex-col items-center justify-center">
        <app-button
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
          feature="confirm"
          display-classes="flex"
          class="mx-auto mb-4"
        />
      </div>
    </fieldset>
  </form>
</template>

<script setup>
import { fetchExcursions } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import useLocale from '@shared/hooks/locale'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors
  // not generated by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const { getAttributeTranslation } = useLocale()
const {
  invalid,
  handleSubmit,
} = useForm(props, {
  emits,
  initialValues: {
    point_of_interest_id: props.resource.id,
  },
})

// Retrieve ids of excursions already related to the poi
const relatedExcursionsIds = props.resource.relationships.excursions.map((exc) => (exc.id))

// ---------- EXCURSIONS OPTIONS ----------

function handleExcursionsSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    fetchExcursions({
      search: searchKeywords,
      filters: {
        limit_radius: false,
        kinds: ['customized', 'root'],
      },
    })
      .then((e) => {
        // Get the new options from the response
        const apiOptions = formatExcursionsOptions(e.data.data)

        // Do not use excursions' options already related to the poi
        options = apiOptions.filter((opt) => (
          !relatedExcursionsIds.includes(opt.value)
        ))
      })
      .finally(() => {
        resolve(options)
      })
  })
}

// Return excursions options used in select control,
// based on excursions resources
function formatExcursionsOptions(excursions) {
  return excursions.map((categoryResource) => ({
    label: getAttributeTranslation(categoryResource.attributes.title),
    value: categoryResource.id,
  }))
}
</script>
