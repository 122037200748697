<template>
  <app-geoloc-filters
    @submitted="handleGeolocUpdated"
  />
</template>

<script setup>
import AppGeolocFilters from '@app/components/ui/AppGeolocFilters.vue'

const emits = defineEmits([
  'geoloc-updated',
])

function handleGeolocUpdated() {
  emits('geoloc-updated')
}
</script>
