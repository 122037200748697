<template>
  <form-group
    :name="`title.${locale}`"
    rules="required"
  />

  <form-group
    name="points_of_interest_search"
    :label="t('validation.attributes.points_of_interest')"
    :hint="t('form.hints.excursion.points_of_interest_search')"
    type="select"
    hide-errors
    :submittable="false"
    :form-control-props="{
      filterable: false,
      options: handlePoisSearch,
    }"
    @changed="handlePoiSelectChanged"
  >
    <template #control-vue-multiselect-option="option">
      <div>
        <strong>
          {{ option.label }}
        </strong>
        <br>
        <em class="text-sm">
          {{ option.subLabel }}
        </em>
      </div>
    </template>
  </form-group>

  <p class="text-xs mb-1">
    {{ t('excursions.form.order_hint') }}
  </p>

  <form-group
    name="excursions_points_of_interest"
    :label="false"
    type="sortable-list"
    :submittable="false"
  >
    <template #control-vue-draggable-item="{ element }">
      <point-of-interest-horizontal-card
        :resource="getPointOfInterestResource(element)"
        :with-link="false"
        hide-actions
      />
    </template>
  </form-group>

  <template
    v-for="(item, index) in form.values.excursions_points_of_interest"
    :key="item.id"
  >
    <form-group
      :name="`excursions_points_of_interest.${index}.id`"
      type="hidden"
    />
    <form-group
      :name="`excursions_points_of_interest.${index}.point_of_interest_id`"
      type="hidden"
    />
    <form-group
      :name="`excursions_points_of_interest.${index}.position`"
      type="hidden"
    />
    <form-group
      :name="`excursions_points_of_interest.${index}._destroy`"
      type="hidden"
    />
  </template>
</template>

<script setup>
import { nextTick, ref, computed } from 'vue'
import {
  uniqBy,
  compact,
  max,
} from 'lodash'
import { useI18n } from 'vue-i18n'

import { fetchPointsOfInterest } from '@shared/http/api'
import useFormFields from '@shared/hooks/form/formFields'
import useGeoloc from '@shared/hooks/geoloc'
import useLocale from '@shared/hooks/locale'
import FormGroup from '@shared/components/form/FormGroup.vue'
import PointOfInterestHorizontalCard from '@app/components/resources/point_of_interest/PointOfInterestHorizontalCard.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other
  // form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const { form } = useFormFields(props)
const { inlineAddress } = useGeoloc()
const { locale, t } = useI18n()
const { getAttributeTranslation } = useLocale()

// "excursions_points_of_interest" form's value
const excursionsPointsOfInterest = computed(() => (
  form.value.values.excursions_points_of_interest || []
))

// "excursions_points_of_interest" form's value without the destroyed one
const excursionsPointsOfInterestNotDestroyed = computed(() => (
  excursionsPointsOfInterest.value.filter((item) => !item._destroy)
))

// Get points of interest resources from the relationship
const relationshipPois = computed(() => (
  props
    .resource
    ?.relationships
    ?.excursions_points_of_interest
    ?.map((exc) => exc?.relationships?.point_of_interest) || []
))

// Check if a point of interest is already select
function isPointOfInterestAlreadySelected(id) {
  return excursionsPointsOfInterestNotDestroyed
    .value
    .map((item) => item.point_of_interest_id)
    .includes(id)
}

// Return points of interest options used in select control,
// based on points of interest resources
function formatPoisOptions(poisResources) {
  return poisResources.map((poiResource) => {
    const subLabel = getPointOfInterestSubLabel(poiResource)

    return {
      label: getPointOfInterestLabel(poiResource),
      subLabel,
      value: poiResource?.id,
    }
  })
}

// Return point interest label
// "poiResource" is a JSON API resource
function getPointOfInterestLabel(poiResource) {
  return getAttributeTranslation(poiResource?.attributes?.title, poiResource?.attributes?.locale)
}

// Return point of interest sublabel
// "poiResource" is a JSON API resource
function getPointOfInterestSubLabel(poiResource) {
  return inlineAddress(poiResource?.relationships?.address)
}

function getPointOfInterestResource(item) {
  // Get the resource from the points of interest found from search API request,
  // and the ones from the relationship
  const pois = apiPois.value
    .concat(relationshipPois.value)
  return pois.find((poi) => (
    poi.id === item.point_of_interest_id
  ))
}

// Points of interest found from API
const apiPois = ref([])

function handlePoisSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    fetchPointsOfInterest({
      'search': searchKeywords,
      'mode': 'index',
      'source': 'app',
      'use_geoloc_setting': true,
    })
      .then((response) => {
        const newPois = response.data.data
        apiPois.value = uniqBy(apiPois.value.concat(newPois), 'id')

        // only use non selected POIs as options
        const poisToAddToOptions = newPois.filter((poi) => !isPointOfInterestAlreadySelected(poi.id))
        const apiOptions = formatPoisOptions(poisToAddToOptions)

        options = compact(uniqBy(options.concat(apiOptions), 'value'))
      })
      .finally(() => {
        resolve(options)
      })
  })
}

async function handlePoiSelectChanged(value) {
  // Check if value isn't null
  // It's null when the search field is reset at the end of this function
  if (value) {
    if (!isPointOfInterestAlreadySelected(value)) {
      // Calc the new item position
      const positions = excursionsPointsOfInterest.value.map((item) => item.position)
      const maxPosition = max(positions) || 0
      const newPosition = maxPosition + 1
      const newIndex = excursionsPointsOfInterest.value.length

      // Push the new item to the list
      form.value.setFieldValue(`excursions_points_of_interest.${newIndex}`, {
        point_of_interest_id: value,
        position: newPosition,
      })
    }

    // Reset the search field
    await nextTick()
    form.value.setFieldValue('points_of_interest_search', null)
  }
}
</script>
