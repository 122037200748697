<template>
  <div
    :class="`above-navbar ${boxStyle} z-30 fixed bg-white shadow-top shadow-theme-500/10 w-full left-0`"
  >
    <div class="relative">
      <div
        :class="`${arrowStyle} absolute bg-white rounded-tl-lg shadow-top shadow-theme-500/10 py-0 px-3`"
      >
        <font-awesome-icon
          :icon="poiArrow"
          class="cursor-pointer"
          @click="handlePoiDisplay"
        />
      </div>

      <div
        v-show="poiDisplay"
        class="flex"
      >
        <router-link
          :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
        >
          <app-rounded-picture
            :picture-url="mainImageUrl"
            picture-size="16"
            :icon-name="iconName"
            icon-size="lg"
            class="mr-3"
          />
        </router-link>

        <div class="w-9/12 mb-2">
          <router-link
            :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
          >
            <div
              class="flex items-center justify-between"
            >
              <span class="font-bold text-sm truncate">
                {{ l10nTitle }}
              </span>

              <span
                v-if="resource.attributes.certified"
              >
                <app-icon
                  library="coolicon"
                  name="circle_check_outline"
                />
              </span>
            </div>
          </router-link>

          <div class="text-gray-500 text-xs">
            <div
              v-if="distanceToDisplay"
              class="mb-1"
            >
              <app-icon
                library="local"
                name="walk"
                size="w-2.5"
                :classes="['mr-1']"
              />
              {{ distanceToDisplay }}
            </div>

            <app-external-link
              v-if="resource.attributes.contact?.phone"
              :link="resource.attributes.contact.phone"
              type="phone"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppExternalLink from '@shared/components/ui/AppExternalLink.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

// ---------- POI ----------

const {
  distanceToDisplay,
  l10nTitle,
  iconName,
  getMainImageUrl,
} = usePointOfInterest(props)

const mainImageUrl = computed(() => (
  getMainImageUrl('xs')
))

const poiDisplay = ref(true)

function handlePoiDisplay() {
  poiDisplay.value = !poiDisplay.value
}

const poiArrow = computed(() => (
  poiDisplay.value
    ? 'chevron-down'
    : 'chevron-up'
))

// ---------- STYLES ----------

const arrowStyle = computed(() => (
  poiDisplay.value
    ? '-right-4 -top-10'
    : 'right-0 -top-6'
))

const boxStyle = computed(() => (
  poiDisplay.value
    ? 'p-4'
    : 'p-0'
))
</script>
