import { ref } from 'vue'

export default function useResourceForm(options = {}) {
  const submitting = ref(false) // Form submitting or not
  const errors = ref({}) // Form errors

  // Function to call when the form is submitted
  function handleSubmit(attributes) {
    // Submittable only if not already submitting
    if (!submitting.value) {
      // Start submitting state
      submitting.value = true

      // Send form data to API
      if (typeof options.apiMethod.value === 'function') {
        options.apiMethod.value(...apiParams(attributes))
          .then((response) => {
            // Empty errors
            errors.value = {}
            // Trigger onSubmitSuccess callback
            if (options.onSubmitSuccess) {
              options.onSubmitSuccess(response)
            }
          })
          .catch((e) => {
            // Keep server-side errors
            if (e.response?.data?.errors) {
              errors.value = e.response.data.errors
            }
          })
          .finally(() => {
            // End submitting state
            submitting.value = false
          })
      }
    }
  }

  // Build JSON params sent to API
  function apiParams(attributes) {
    const params = []

    // Provide ID as first parameter if present
    if (options.resource?.value?.id) {
      params.push(options.resource.value.id)
    }

    // Provide payload as next parameter
    params.push({
      data: {
        type: options.resourceType,
        attributes,
      },
    })

    return params
  }

  return {
    errors,
    submitting,
    handleSubmit,
  }
}
