<template>
  <app-button
    v-if="resource.authorizations.share"
    font-size="sm"
    feature="share"
    class="my-1 ml-4"
    :emphasis="emphasis"
    :hide-text="hideText"
    :hide-icon="hideIcon"
    :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
    @click="handleModalAction"
  />

  <!-- Modal -->
  <app-overlay
    :show="confirmModalDisplayed"
    @clicked="handleCancel"
  >
    <app-modal
      @closed="handleCancel"
    >
      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ t('points_of_interest.share.heading') }}
        </h4>
      </template>

      <p class="text-left mb-4">
        {{ t(
          'points_of_interest.share.select_which_users_alt',
          { point_of_interest_title: l10nTitle }
        ) }}
      </p>

      <point-of-interest-share-form
        :resource="resource"
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { sharePointOfInterest } from '@shared/http/api'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import PointOfInterestShareForm from '@app/components/resources/point_of_interest/PointOfInterestShareForm.vue'

const store = useStore()
const { t } = useI18n()

const confirmModalDisplayed = ref(false)
const formErrors = ref({})
const formSubmitting = ref(false)

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
  // Hide icon or not
  hideIcon: {
    type: Boolean,
    default: true,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true
    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    sharePointOfInterest(props.resource.id, params)
      .then(() => {
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('points_of_interest.share.success'),
            type: 'success',
          },
        )
        confirmModalDisplayed.value = false
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- POI ----------

const { l10nTitle } = usePointOfInterest(props)

// ---------- MODAL ----------

function handleModalAction() {
  confirmModalDisplayed.value = true
}

function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
