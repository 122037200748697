import { useRouter as useVueRouter } from 'vue-router'
import { isNil } from 'lodash'

export default function useRouter() {
  const router = useVueRouter()

  // Go back if possible, else go to homepage
  function back() {
    if (canGoBack()) {
      router.back()
    } else {
      router.push('/')
    }
  }

  // Check if a back route is present in history
  function canGoBack() {
    return !isNil(window.history.state?.back)
  }

  return {
    ...router,
    back,
  }
}
