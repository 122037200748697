import i18n from '@shared/i18n/vue-i18n'
import { countries as countriesList } from 'countries-list'
import { computed } from 'vue'

import { translationExists } from '@shared/helpers/translations'
import { getAttributeTranslation } from '@shared/helpers/attributes'

export default function useGeoloc() {
  /**
   * Return a country name, from its code
   *
   * @param {string} code
   * @param {string | null} forcedLocale
   * @returns {string | null}
   */
  function countryCodeToFullName(code, forcedLocale = null) {
    // Try to translate country in the provided locale
    if (translationExists(`countries.${code?.toUpperCase()}`, forcedLocale)) {
      return i18n.global.t(`countries.${code?.toUpperCase()}`, {}, { locale: forcedLocale })
    }

    // Or, try in the app locale
    // Try to translate country in the provided locale
    if (translationExists(`countries.${code?.toUpperCase()}`)) {
      return i18n.global.t(`countries.${code?.toUpperCase()}`)
    }

    // Or use default english name
    const country = Object.entries(countries).find((c) => c[0] === code)
    if (country) {
      return country[1].name
    }
  }

  /**
   * Format address to inline
   *
   * @param {object} address
   * @param {string | null} locale
   * @returns {string}
   */
  function inlineAddress(address, locale = null) {
    let subLabel = getAttributeTranslation(address?.attributes?.line1, locale) ? `${getAttributeTranslation(address.attributes.line1, locale)}, ` : ''
    subLabel += getAttributeTranslation(address?.attributes?.line2, locale) ? `${getAttributeTranslation(address.attributes.line2, locale)}, ` : ''
    subLabel += address?.attributes?.zip || ''
    subLabel += getAttributeTranslation(address?.attributes?.city, locale) ? ` ${getAttributeTranslation(address.attributes.city, locale)}, ` : ''
    subLabel += getAttributeTranslation(address?.attributes?.dependent_locality, locale) ? ` ${getAttributeTranslation(address.attributes.dependent_locality, locale)}, ` : ''
    subLabel += getAttributeTranslation(address?.attributes?.administrative_area, locale) ? ` ${getAttributeTranslation(address.attributes.administrative_area, locale)}, ` : ''
    subLabel += countryCodeToFullName(address?.attributes?.country, locale) || ''

    return subLabel
  }

  // Get formatted approximate location
  // e.g.: "Paris, France"
  function approximateLocation(address) {
    if (getAttributeTranslation(address?.attributes?.city) && address?.attributes?.country) {
      return `${getAttributeTranslation(address.attributes.city)}, ${countryCodeToFullName(address.attributes.country)}`
    }

    return null
  }

  const countries = computed(() => (
    Object.entries(countriesList).map((c) => ({
      value: c[0],
      label: countryCodeToFullName(c[0]),
    }))
  ))

  return {
    inlineAddress,
    countryCodeToFullName,
    approximateLocation,
    countries,
  }
}
