<template>
  <app-button
    :icon-props="iconProps"
    :hide-icon="false"
    :background-classes="selected ? 'bg-theme-500' : 'bg-white'"
    :font-color-classes="selected ? 'text-white' : 'text-theme-500'"
    rounded="rounded-none"
    :title="t(`excursions.direction.${mode}`)"
    :disabled="selected"
    @click="handleModeSelected(mode)"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Mode name (e.g.: "walking" or "driving")
  mode: {
    type: String,
    required: true,
  },
  // app-icon component props
  iconProps: {
    type: Object,
    required: true,
  },
  // Selected or not
  selected: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'selected',
])

const { t } = useI18n()

function handleModeSelected() {
  emits('selected', props.mode)
}
</script>
