<template>
  <div class="flex">
    <!-- Image -->
    <router-link
      v-slot="{ navigate }"
      custom
      :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
    >
      <div
        :class="`w-1/3 ${imageStyle} bg-center ${withLink ? 'cursor-pointer' : ''}`"
        :style="`background-image: url(${mainImageUrl});`"
        @click="withLink ? navigate() : null"
      />
    </router-link>

    <div class="w-2/3 p-3">
      <!-- Details -->
      <router-link
        v-slot="{ navigate }"
        custom
        :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
      >
        <div
          :class="`mb-3 ${withLink ? 'cursor-pointer' : ''}`"
          @click="withLink ? navigate() : null"
        >
          <div
            class="flex items-center justify-between"
          >
            <!-- Title -->
            <span class="font-bold text-sm truncate">
              {{ l10nTitle }}
            </span>

            <span
              v-if="resource.attributes.certified"
            >
              <app-icon
                library="coolicon"
                name="circle_check_outline"
              />
            </span>
          </div>

          <div class="text-gray-500 text-xs">
            <div
              class="flex items-center"
            >
              <!-- Category -->
              <span
                v-if="getAttributeTranslation(resource.relationships.category?.attributes?.label)"
                class="truncate -ml-1"
              >
                <app-icon
                  library="coolicon"
                  name="tag-outline"
                  size="text-base"
                />
                {{ getAttributeTranslation(resource.relationships.category.attributes.label) }}
              </span>

              <!-- Stars -->
              <template
                v-if="resource.attributes.stars > 0"
              >
                <span class="mx-1">
                  &#183;
                </span>

                <span
                  v-for="i in resource.attributes.stars"
                  :key="i"
                >
                  <app-icon
                    library="fontawesome"
                    name="star"
                  />
                </span>
              </template>
            </div>

            <!-- Distance -->
            <div v-if="distanceToDisplay">
              <app-icon
                library="local"
                name="walk"
                size="w-2.5"
                :classes="['mr-1']"
              />
              {{ distanceToDisplay }}
            </div>

            <!-- Tags -->
            <div
              v-if="resource.relationships.tags"
              class="flex flex-wrap"
            >
              <div
                v-for="(tag, i) in resource.relationships.tags"
                :key="i"
                class="bg-gray-500 text-white py-0.5 px-2 w-fit rounded-full mr-1 last:mr-0 my-0.5"
              >
                {{ getAttributeTranslation(tag.attributes.label) }}
              </div>
            </div>

            <!-- Location -->
            <div
              v-if="approximateLocation(resource.relationships.address)"
              class="flex items-center"
            >
              <app-icon
                library="coolicon"
                name="location_outline"
                class="-ml-1.5 mr-0.5"
              />
              <span class="truncate">
                {{ approximateLocation(resource.relationships.address) }}
              </span>
            </div>
          </div>
        </div>
      </router-link>

      <point-of-interest-actions
        v-if="!hideActions"
        :resource="resource"
        class="justify-end"
        @destroyed="destroyCallback"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useGeoloc from '@shared/hooks/geoloc'
import useLocale from '@shared/hooks/locale'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import PointOfInterestActions from '@app/components/resources/point_of_interest/PointOfInterestActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called
  // on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
  // Add a link to resource
  // show page or not
  withLink: {
    type: Boolean,
    default: true,
  },
})

const {
  distanceToDisplay,
  l10nTitle,
  getMainImageUrl,
  isPlaceholderImage,
} = usePointOfInterest(props)

// ---------- LOCALE ----------

const { getAttributeTranslation } = useLocale()

// ---------- GEOLOC ----------

const { approximateLocation } = useGeoloc()

// ---------- IMAGE ----------

const mainImageUrl = computed(() => (
  getMainImageUrl('sm')
))

const imageStyle = computed(() => (
  isPlaceholderImage(mainImageUrl.value)
    ? 'bg-contain bg-no-repeat'
    : 'bg-cover'
))
</script>
