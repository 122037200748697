<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting">
      <form-group
        rules="required"
        class="with-border-on-control"
        name="reason_type"
        type="select"
        :form-control-props="{
          filterable: false,
          options: reasonTypeOptions,
        }"
      />

      <form-group
        name="reason_details"
        class="with-border-on-control"
        type="textarea"
        :form-control-props="{
          rows: 6,
        }"
      />

      <app-button
        feature="confirm"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        class="float-right mt-4 md:float-none"
      />
    </fieldset>
  </form>
</template>

<script setup>
import useForm from '@shared/hooks/form/form'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const { handleSubmit, invalid } = useForm(props, { emits })
const { reasonTypeOptions } = useSelectOptions()
</script>
