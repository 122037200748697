import { upperCase, replace } from 'lodash'

export default function useTranslation() {
  /**
   * Return "snakecased" uppercase value for translation enum key, Not use snakeCase's lodash method, does not work in
   * these cases.
   *
   * @param stringValue
   * @returns {*}
   */
  const enumAttribute = (stringValue) => replace(upperCase(stringValue), ' ', '_')

  return {
    enumAttribute,
  }
}
