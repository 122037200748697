<template>
  <form
    id="excursion-form"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting">
      <excursion-form-fields
        :resource="resource"
      />

      <teleport-wrapper to="#header-right">
        <app-button
          form="excursion-form"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
          feature="save"
        />
      </teleport-wrapper>

      <app-button
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        feature="save"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import ExcursionFormFields from '@app/components/resources/excursion/ExcursionFormFields.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors
  // not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit, invalid,
} = useForm(props, { emits })
</script>
